import React, { useState } from 'react';

import './PasswordInput.css';
import './PasswordInputAlt.css';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { VARIANT } from '../../misc/constants';

function PasswordInput({value, className, onChange, isTemp = false, isPassword1 = null, statusMsg = {msg: '', isError: false}}) {
  const [isHidden, setIsHidden] = useState(true);

  let placeholderText = "Password"
  if (isTemp) {
    placeholderText = "Enter Temporary Password"
  } else if (isPassword1) {
    placeholderText = "New Password"
  } else if (isPassword1 !== null) {
    placeholderText = "Confirm New Password"
  }

  return (
    <div className={"password-container" + VARIANT + (statusMsg.isError ? " inputError" : '')}>
      <input
        className={`password` + VARIANT + `${className || ''}`}
        name='password'
        type={isHidden ? 'password' : 'text'}
        placeholder={placeholderText}
        value={value}
        onChange={onChange}
        required autoFocus={className !== ' password2'}
        data-testid={"password" + (isPassword1 ? "1" : "2")}
        onClick={onChange}
      />
      <div className={"eye-wrapper" + VARIANT} onClick={() => setIsHidden(!isHidden)}>
        {!isHidden ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
      </div>
    </div>
  )
}

export default PasswordInput;
