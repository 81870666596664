import React from "react";

import './PickUpdateView.css';
import StatusMsg from "../../StatusMsg/StatusMsg";
import { VARIANT } from "../../../misc/constants";

function PickUpdateView(props) {
    return (
        <form onSubmit={() => window.location.reload()}>
            <p className={"view-header" + VARIANT}>Select Factor to Update</p>
            <p className={"view-title" + VARIANT}>Select factor to update.</p>
            <StatusMsg statusMsg={props.statusMsg}/>
            <button data-testid="pickUpdate-pass-button" className={"cancel" + VARIANT} onClick={() => props.onSubmit(true)}>Password</button>
            <button data-testid="pickUpdate-MFA-button" className={"cancel" + VARIANT} onClick={() => props.onSubmit(false)}>Multi-factor Authentication</button>
            <div style={{height: '65px'}}></div>
            <button data-testid="pickUpdate-exit-button" className={"proceed" + VARIANT} type="submit">Exit</button>
        </form>
    )
}

export default PickUpdateView;