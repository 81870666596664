import React from "react";

import './PickMFAView.css';
import { VARIANT } from "../../../misc/constants";

function PickMFAView(props) {
  const onSubmit = (event) => {
    event.preventDefault();  // prevents page reload
    props.onBack();
  }

  return (
    <form onSubmit={onSubmit}>
      <p className={"view-header" + VARIANT}>Select MFA Method</p>
      <p className={"view-title" + VARIANT}>Select Multi-factor Authentication method.</p>
      <button data-testid="pickMFA-auth-button" className={"cancel" + VARIANT} onClick={() => props.onSubmit(false)}>Authenticator App <span>(Recommended)</span></button>
      <button data-testid="pickMFA-SMS-button" className={"cancel" + VARIANT} onClick={() => props.onSubmit(true)}>SMS</button>
      {props.onBack ?
      <>
        <div style={{height: '60px'}}></div>
        <button data-testid="pickMFA-back-button" className={"proceed" + VARIANT} type="submit">Back</button>
      </> : null}
    </form>
  )
}

export default PickMFAView;