import React, { createRef, useReducer, useRef, useState } from 'react';

import MFADigits, { digitReducer } from '../../MFADigits/MFADigits';
import StatusMsg from '../../StatusMsg/StatusMsg';

import './UpdateMFAAppView.css';
import './UpdateMFAAppViewAlt.css';
import QRModal from '../../QRModal/QRModal';
import ErrorMsg from '../../ErrorMsg/ErrorMsg';
import { VARIANT } from '../../../misc/constants';

const QRIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
      <path d="M0 7.16667V0.5H6.66667V7.16667H0ZM1.66667 5.5H5V2.16667H1.66667V5.5ZM0 15.5V8.83333H6.66667V15.5H0ZM1.66667 13.8333H5V10.5H1.66667V13.8333ZM8.33333 7.16667V0.5H15V7.16667H8.33333ZM10 5.5H13.3333V2.16667H10V5.5ZM13.3333 15.5V13.8333H15V15.5H13.3333ZM8.33333 10.5V8.83333H10V10.5H8.33333ZM10 12.1667V10.5H11.6667V12.1667H10ZM8.33333 13.8333V12.1667H10V13.8333H8.33333ZM10 15.5V13.8333H11.6667V15.5H10ZM11.6667 13.8333V12.1667H13.3333V13.8333H11.6667ZM11.6667 10.5V8.83333H13.3333V10.5H11.6667ZM13.3333 12.1667V10.5H15V12.1667H13.3333Z" fill={VARIANT === '' ? '#276EDC' : "white"}/>
    </svg>
  )
}

function UpdateMFAAppView({ statusMsg, totp, onBack, onSubmit, resetStatusMsg }) {
  const digitRefs = useRef([]);
  const [digits, digitDispatch] = useReducer(digitReducer, Array(6).fill(''));
  digitRefs.current = digits.map((_, idx) => digitRefs.current[idx] ?? createRef());

  const [show, setShow] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault(); // prevents page reload
    const digitsValues = digitRefs.current.map((digitRef) => digitRef.current.value).join('');
    onSubmit(digitsValues, 'app');
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <p className={"view-header" + VARIANT}>Update Authenticator App</p>
        <p className={"view-title" + VARIANT}>Scan QR code in authenticator app and enter MFA code.</p>
        {/* <StatusMsg statusMsg={statusMsg} /> */}
        <div className="qr-code-container">
          <div className="qr-wrapper">
            <p className={"qr-show" + VARIANT} onClick={() => setShow(true)}>
              <QRIcon/>
              Show QR Code
            </p>
          </div>
          <QRModal show={show} setShow={setShow} totp={totp}/>
        </div>
        {/*<p>Enter 6-digit MFA verification code</p>*/}
        <MFADigits
          digits={digits}
          digitDispatch={digitDispatch}
          digitRefs={digitRefs}
          error={statusMsg.isError}
          resetStatusMsg={resetStatusMsg}
        />
        <ErrorMsg statusMsg={statusMsg} onMFA={true}/>
        <div className="vertical-splitter">
          <button
            type="submit"
            className={"proceed" + VARIANT}
            disabled={!(digits.every((entry) => entry.length))}
            data-testid="updateApp-submit-button"
          >Submit
          </button>
          <button data-testid="updateApp-back-button" type="button" className={"cancel" + VARIANT} onClick={onBack}>Back</button>
        </div>
      </div>
    </form>
  );
}

export default UpdateMFAAppView;
