const ENV_NAME = process.env.NODE_ENV
const CLARITY_ID = {
	development: 'hrmh0h28j0',
	test: 'hrmh0h28j0',
	staging: 'hrmgusnpnp',
	production: 'hrmgez0xem',
}[ENV_NAME];

// eslint-disable-next-line
export const initClarity = () => {
	(function (c, l, a, r, i, t, y) {
		// eslint-disable-next-line prefer-rest-params
		c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments); };
		t = l.createElement(r); t.async = 1; t.src = `https://www.clarity.ms/tag/${i}`;
		// eslint-disable-next-line prefer-destructuring
		y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
	}(window, document, 'clarity', 'script', CLARITY_ID));
};