import React, { useRef, useState } from "react";
import { BsPersonCircle } from 'react-icons/bs';
import { GrClose } from 'react-icons/gr';
import { AiOutlineClose } from "react-icons/ai";

import StatusMsg from "../../StatusMsg/StatusMsg";

import './PasswordView.css';
import './PasswordViewAlt.css';
import PasswordInput from "../../PasswordInput/PasswordInput";
import AudienceMsg from "../../AudienceMessage/AudienceMsg";
import ErrorMsg from "../../ErrorMsg/ErrorMsg";
import { VARIANT } from "../../../misc/constants";

function PasswordView(props) {
  const passwordTypeText = props.isTemp ? 'Temporary Password' : "Password"
  const [password, setPassword] = useState('');

  const disabledButton = password.length <= 0

  const onSubmit = (event) => {
    event.preventDefault();  // prevents page reload
    props.onSubmit(password);
  }

  const handleChange = (event) => {
    const newValue = event.target.value;
    setPassword(newValue);
    props.resetStatusMsg();
  }

  const renderForgotPassword = () => {
    return <p className={"forgot-pwd" + VARIANT} onClick={props.onForgot}>Forgot Password?</p>
  }

  const renderTempTitle = () => {
    return (
      <div>
        <p className="view-title">
          If you cannot find your temporary password from accounts@surgicalsafety.com in
          your inbox, please request that your administrator reset your account.
        </p>
      </div>
    )
  }

  return (
    <form onSubmit={onSubmit}>
      <p className={"view-header" + VARIANT}>{`${!props.isTemp ? 'Enter Your ' : ''}${passwordTypeText}`}</p>
      {props.isTemp ? renderTempTitle() : null}
      <div className='email-pill-wrapper'>
        <div className={'email-pill-container' + VARIANT}>
          <BsPersonCircle />&nbsp;
          <div className="email-pill-email">{props.email}&nbsp;</div>
          <div className={"cross-wrapper" + VARIANT} onClick={props.onWrongEmail}><AiOutlineClose /></div>
        </div>
      </div>
      <div style={{height: '15px'}}/>
      {/* <StatusMsg statusMsg={props.statusMsg}/> */}
      <AudienceMsg audienceMsg={props.audienceMsg} />
      <div style={props.isTemp ? {height: '30px'} : {height: '60px'}}/>
      <PasswordInput
        value={password}
        onChange={handleChange}
        isTemp={props.isTemp}
        statusMsg={props.statusMsg}
      />
      <ErrorMsg statusMsg={props.statusMsg}/>
      {!props.isTemp ? renderForgotPassword() : null}
      <div style={props.isTemp ? {height: '5px'} : {height: '50px'}}></div>
      <button data-testid={"password-proceed-button"} className={"proceed" + VARIANT} type="submit" disabled={disabledButton}>Next</button>
    </form>
  )
}

export default PasswordView;
